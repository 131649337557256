<template>
    <div class="container">
        <div class="leftContainer">
            <div class="left-top">
                <div class="title">
                    <span>设备详情</span>
                </div>
                <div class="tableTitle">
                    <div class="titleItem" style="width: 28%;">景区名</div>
                    <div class="titleItem" style="width: 28%;">设备名</div>
                    <div class="titleItem" style="width: 22%;">在线数</div>
                    <div class="titleItem" style="width: 22%;">离线数</div>
                </div>
                <div class="cellCon">
                    <div class="cell" v-for="(item,index) in monitorList" :key="index">
                        <div class="cellItem" style="width: 28%;">{{ item.installPlace }}</div>
                        <div class="cellItem" style="width: 28%;">{{item.name}}</div>
                        <div class="cellItem" style="width: 22%;">1</div>
                        <div class="cellItem" style="width: 22%;">0</div>
                    </div>
                    
                </div>
            </div>
            <div class="left-bottom">
                <div class="title">
                    <span>设备在线状态统计</span>
                </div>
                <div class="progressCon">
                    <Progress id="1" :name="'左摆闸'" :value="75" :color1="'#0078FF'" :color2="'#7BE16E'"></Progress>
                    <Progress id="2" :name="'右摆闸'" :value="78" :color1="'#E9611E'" :color2="'#E5E88A'"></Progress>
                    <Progress id="3" :name="'双通道'" :value="60" :color1="'#EA20AA'" :color2="'#E1B26E'"></Progress>
                    <Progress id="4" :name="'防疫机'" :value="70" :color1="'#7837FC'" :color2="'#68CDEE'"></Progress>
                    <Progress id="5" :name="'云广播'" :value="78" :color1="'#27BCE7'" :color2="'#DCE06F'"></Progress>
                </div>
            </div>
        </div>
        <div class="centerContainer">
            <div class="center-top">
                    <div class="center-top-name">
                        {{ currentName }}
                    </div>
                    <div style="height:calc(100% - 70px)">
                        <video id="videoId" style="width:100%;height:100%" class="video-js">
                        </video>
                    </div>
                   
                    
            </div>
            <div class="center-bottom">
                
                <div class="centerItem" v-for="(item,index) in monitorList" :key="index" @click="changeMonitor(index,item)">
                    <div class="centerItem-name">
                        {{item.name}}
                    </div>
                    <div class="centerItem-image">
                        <el-image :src="item.titleUrl" >
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                        </el-image>
                    </div>
                    
                </div>
                
            </div>
        </div>
        <div class="rightContainer">
            <div class="right-top">
                <div class="topCon">
                    <div class="flex align-center">
                        <div style="position: relative;">
                            <div class="circleItemL"></div>
                            <div class="circleText" style="color: #F25959;">告警</div>
                        </div>
                        <span class="margin-left-xs text-bold" style="font-size: 28px;color:#00D8FF;">7<span
                                style="font-size: 14px;color: #ffffff;">条</span></span>
                    </div>
                    <div class="flex align-center">
                        <div style="position: relative;">
                            <div class="circleItemR"></div>
                            <div class="circleText" style="color: #FF9E2C;">故障</div>
                        </div>
                        <span class="margin-left-xs text-bold" style="font-size: 28px;color:#00D8FF;">3<span
                                style="font-size: 14px;color: #ffffff;">条</span></span>
                    </div>
                </div>
                <div class="bottomCon">
                    <div class="left">
                        <div class="cell text-cut">景区A-001号设备发出告警</div>
                        <div class="cell text-cut">景区A-001号设备发出告警</div>
                        <div class="cell text-cut">景区A-001号设备发出告警</div>
                        <div class="cell text-cut">景区A-001号设备发出告警</div>
                        <div class="cell text-cut">景区A-001号设备发出告警</div>
                        <div class="cell text-cut">景区A-001号设备发出告警</div>
                        <div class="cell text-cut">景区A-001号设备发出告警</div>
                    </div>
                    <div class="right">
                        <div class="cell text-cut">景区A-001号设备发出故障</div>
                        <div class="cell text-cut">景区A-001号设备发出故障</div>
                        <div class="cell text-cut">景区A-001号设备发出故障</div>
                    </div>
                </div>
            </div>
            <div class="right-bottom">
                <div class="title">
                    <span>景区车辆情况统计</span>
                </div>
                <div style="width: 100%;height: 89%;position: relative;">
                    <CircleData :id="'monitor'"></CircleData>
                    <div class="circleChild">
                        <div class="childTitle">占比统计</div>
                        <span>500</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CircleData from "./components/circleData.vue"
import Progress from "./components/progress.vue"
import videojs from "video.js";
import "videojs-contrib-hls";
export default {
    components: {
        CircleData,
        Progress,
    },
    mounted() {
        this.getMonitorList()
     },
    data() {
        return {
            dvCode:'36118101',
            monitorList:[],
            playerList:[],
            currentIndex:0,
            currentUrl:'',
            currentName:'',
            player:null,
            interval:null
        };
    },
    beforeDestroy() {
        //  组件销毁时，清除播放器
        if (this.player) {
            this.player.dispose(); // 该方法会重置videojs的内部状态并移除dom
        }
    },
    watch:{
        currentIndex:{
            immediate:true,
            handler(val){
                this.currentUrl  = this.monitorList[val].url
                this.currentName = this.monitorList[val].name
                // if (this.interval) {
                //     clearInterval(this.interval)
                // }
                // this.interval = setInterval(() => {
                    this.initVideo(this.currentUrl)
                // },100);
            }
        }
    },
    methods: {
        async getMonitorList(){
            let params = {
                dvCode:this.dvCode
            }
            let r =  await this.$api.getMonitorData(params)
            this.monitorList = r.data
            this.currentUrl = this.monitorList[this.currentIndex].url
            this.currentName = this.monitorList[this.currentIndex].name
            // if(this.interval){
            //     clearInterval(this.interval)
            // }
            // this.interval = setInterval(() => {
                this.initVideo(this.currentUrl)
            // },100);
        },
        changeMonitor(index){
            this.currentIndex = index
        },
        initVideo(url){
            this.player = videojs('videoId',{
                autoplay: true, // 设置自动播放
                muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音 （Chrome66及以上版本，禁止音视频的自动播放）
                preload: "auto", // 预加载
                controls: true, // 显示播放的控件
            });
            this.player.src([
                {
                    src:url,
                    type:'application/x-mpegURL'
                }
            ])
        },
        
        
    },
};
</script>
<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    color: #7abdf0;
    font-size: 16px;
    font-weight: 500;
    // background-image: url("../../assets/image/bg-wangge.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right bottom;
    padding: 1% 1.6% 1.6% 1.6%;

    .baseCell {
        width: 100%;
        height: 36px;
        background-image: url("../../assets/image/common05.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        display: flex;
        align-items: center;
    }

    .title {
        width: 100%;
        height: 45px;
        padding: 0px 8%;
        font-size: 20px;
        font-weight: 400;
        color: #17CAF0;
        display: flex;
        align-items: center;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 18px;
            left: 15px;
            width: 14px;
            height: 14px;
            background: #17CAF0;
            opacity: 0.3;
            border-radius: 50%;
        }

        &::after {
            content: '';
            position: absolute;
            top: 21px;
            left: 18px;
            width: 8px;
            height: 8px;
            background: #17CAF0;
            border-radius: 50%;
        }
    }

    .conTop {
        width: 100%;
        height: 53%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 0px 0px;
    }

    .conBottom {
        width: 100%;
        height: 43%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 0px 0px;
    }

    .leftContainer {
        width: 25%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .left-top {
            @extend .conTop;
            background-image: url("../../assets/image/common01.png");
            display: flex;
            flex-direction: column;

            .tableTitle {
                width: 100%;
                height: 11%;
                display: flex;
                align-items: center;
                padding: 0px 3%;

                .titleItem {
                    display: flex;
                    justify-content: center
                }
            }

            .cellCon {
                width: 100%;
                height: 72%;
                padding: 0px 3%;
                // display: flex;
                // flex-direction: column;
                overflow: auto;

                .cell {
                    @extend .baseCell;
                    font-size: 14px;
                    font-weight: bold;
                    color: #FFFFFF;
                    margin-bottom: 16px;

                    .cellItem {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        .left-bottom {
            @extend .conBottom;
            background-image: url("../../assets/image/common02.png");

            .progressCon {
                width: 100%;
                height: 89%;
                padding: 6% 0px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }

    .centerContainer {
        width: 47%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .center-top {
            @extend .conTop;
            padding:0px 38px 36px 38px;
            background-image: url("../../assets/image/common03.png");
            &-name{
                height: 70px;
                text-align: center;
                line-height: 70px;
                font-size: 24px;
            }
        }

        .center-bottom {
            @extend .conBottom;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: space-between;

            .centerItem {
                width: 32%;
                height: 48%;
                padding: 0px 14px 12px;
                
                background-image: url("../../assets/image/common04.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: 0px 0px;
                &-image{
                    width: 100%;
                    height: 82%;
                    margin: 0 auto;
                    overflow: hidden;
                    image{
                        width:100%;
                        height:100%;
                    }
                   
                }
                &-name{
                    width:100%;
                    height: 18%;
                    text-align: center;
                }
            }
        }
    }

    .rightContainer {
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .right-top {
            @extend .conTop;
            background-image: url("../../assets/image/common01.png");

            .topCon {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 38px;

                .circleItem {
                    width: 115px;
                    height: 115px;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: 0px 0px;
                    animation: rotateVbtn 1s linear infinite;

                    span {
                        animation: rotateRe 1s linear infinite;
                    }

                    @keyframes rotateVbtn {
                        0% {
                            transform: rotate(0)
                        }

                        100% {
                            transform: rotate(360deg)
                        }
                    }

                    @keyframes rotateRe {
                        0% {
                            transform: rotate(360deg)
                        }

                        100% {
                            transform: rotate(0)
                        }
                    }
                }

                .circleItemL {
                    @extend .circleItem;
                    background-image: url("../../assets/image/monitor01.png");
                    color: #F25959;
                }

                .circleItemR {
                    @extend .circleItem;
                    background-image: url("../../assets/image/monitor02.png");
                    color: #FF9E2C;
                }

                .circleText {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    font-size: 26px;
                    font-weight: 400;
                }
            }

            .bottomCon {
                width: 100%;
                display: flex;
                height: 50%;

                .left {
                    width: 50%;
                    height: 100%;
                    padding: 0px 12px;
                    overflow: auto;

                    .cell {
                        @extend .baseCell;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: bold;
                        color: #FFFFFF;
                        margin-bottom: 16px;
                    }
                }

                .right {
                    width: 50%;
                    height: 100%;
                    padding: 0px 12px;
                    overflow: auto;

                    .cell {
                        @extend .baseCell;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: bold;
                        color: #FFFFFF;
                        margin-bottom: 16px;
                    }
                }
            }
        }

        .right-bottom {
            @extend .conBottom;
            background-image: url("../../assets/image/common02.png");

            .circleChild {
                width: 50%;
                height: 70%;
                position: absolute;
                top: 0px;
                left: 0px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 56px;
                font-weight: 400;
                color: #17CAF0;

                .childTitle {
                    font-size: 19px;
                    font-weight: bold;
                    color: #FFFFFF;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 10px;
                        left: -18px;
                        width: 6px;
                        height: 6px;
                        background: #FFE995;
                    }
                }
            }
        }
    }
}
</style>