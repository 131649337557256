<template>
    <div :id="'main' + id" :style="{ width: '30%', height: '50%' }"></div>
</template>
<script>
export default {
    props: {
        id: String,
        name: String,
        value: Number,
        color1: String,
        color2: String,
    },
    components: {},
    mounted() {
        this.draw();
    },
    data() {
        return {
            random: Math.round(Math.random() * 80 + 20),
        };
    },
    methods: {
        draw() {
            var chartDom = document.getElementById("main" + this.id);
            var myChart = this.$echarts.init(chartDom, "dark");
            window.addEventListener("resize", function () {
                myChart.resize();
            });

            const option = {
                title: [
                    {
                        text: this.value + "%",
                        x: "center",
                        top: "50%",
                        textStyle: {
                            color: "#17CAF0",
                            fontSize: 22,
                            fontWeight: "bold",
                        },
                    },
                    {
                        text: this.name,
                        x: "center",
                        top: "30%",
                        textStyle: {
                            fontSize: 16,
                            color: "#ffffff",
                            fontFamily: "DINAlternate-Bold, DINAlternate",
                            foontWeight: "bold",
                        },
                    },
                ],
                backgroundColor: "",
                polar: {
                    radius: ["70%", "90%"],
                    center: ["50%", "50%"],
                },
                angleAxis: {
                    max: 100,
                    show: false,
                },
                radiusAxis: {
                    type: "category",
                    show: true,
                    axisLabel: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                },
                series: [
                    {
                        name: "",
                        type: "bar",
                        roundCap: true,
                        barWidth: 90,
                        showBackground: true,
                        backgroundStyle: {
                            color: "#0b4874",
                        },
                        data: [this.value],
                        coordinateSystem: "polar",
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                    {
                                        offset: 0,
                                        color: this.color1,
                                    },
                                    {
                                        offset: 1,
                                        color: this.color2,
                                    },
                                ]),
                            },
                        },
                    },
                    {
                        name: "",
                        type: "pie",
                        startAngle: 80,
                        radius: ["56%"],
                        hoverAnimation: false,
                        center: ["50%", "50%"],
                        itemStyle: {
                            color: "rgba(66, 66, 66, .1)",
                            borderWidth: 1,
                            borderColor: "#152d43",
                        },
                        data: [100],
                    },
                    {
                        name: "",
                        type: "pie",
                        startAngle: 80,
                        radius: ["38%"],
                        hoverAnimation: false,
                        center: ["50%", "50%"],
                        itemStyle: {
                            color: "#152d43",
                            borderWidth: 1,
                            borderColor: "#152d43",
                        },
                        data: [100],
                    },
                ],
            };
            option && myChart.setOption(option);
        },
    },
};
</script>
<style lang="scss" scoped>
</style>