<template>
  <div :id="'main' + id" :style="{ width: '100%', height: '100%' }"></div>
</template>
<script>
export default {
  props: {
    id: String,
  },
  components: {},
  mounted() {
    this.draw();
  },
  data() {
    return {
      random: Math.round(Math.random() * 80 + 20),
    };
  },
  methods: {
    draw() {
      var chartDom = document.getElementById("main" + this.id);
      var myChart = this.$echarts.init(chartDom, "dark");
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      var ydata = [
        {
          name: "景区A",
          value: 100,
          percent: "20",
          itemStyle: {
            color: {
              type: "linear",
              colorStops: [
                {
                  offset: 0,
                  color: "#8A66FF", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#D1AAFF", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
        },
        {
          name: "景区B",
          value: 125,
          percent: "25",
          itemStyle: {
            color: {
              type: "linear",
              colorStops: [
                {
                  offset: 0,
                  color: "#CCA1FF", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#FF6DD1", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
        },
        {
          name: "景区C",
          value: 175,
          percent: "35",
          itemStyle: {
            color: {
              type: "linear",
              colorStops: [
                {
                  offset: 0,
                  color: "#0081E4", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#0FDAFF", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
        },
        {
          name: "景区D",
          value: 100,
          percent: "20",
          itemStyle: {
            color: {
              type: "linear",
              colorStops: [
                {
                  offset: 0,
                  color: "#75FFFD", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#FFB96D", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
        },
      ];
      // var xdata = ["逆行", "超速"];
      let objData = this.array2obj(ydata, "name");
      var legendData1 = [];
      var legendData2 = [];
      for (var i = 0; i < ydata.length; i++) {
        var halfLength = Math.ceil(ydata.length / 2);
        var itemName = ydata[i].name;
        if (i < halfLength) {
          legendData1.push(itemName);
        } else {
          legendData2.push(itemName);
        }
      }
      const option = {
        backgroundColor: "",
        tooltip: {
          trigger: "item",
          formatter: function (parms) {
            var str =
              '车辆数' +
              "</br>" +
              parms.marker +
              "" +
              parms.data.name +
              "</br>" +
              "数量：" +
              parms.data.value +
              "</br>" +
              "占比：" +
              parms.percent +
              "%";
            return str;
          },
        },
        legend: [
          {
            type: "scroll",
            orient: "vertical",
            icon: "circle",
            bottom: 30,
            left: '12%',
            itemGap: 15,
            formatter: function (...name) {
              console.log(name);
              return `${name}  ${objData[name].percent}%`;
            },
            textStyle: {
              color: "#ffffff",
            },
            data: legendData1,
          },
          {
            type: "scroll",
            orient: "vertical",
            icon: "circle",
            bottom: 30,
            left: '65%',
            itemGap: 15,
            textStyle: {
              color: "#ffffff",
            },
            formatter: function (...name) {
              return `${name}  ${objData[name].percent}%`;
            },
            data: legendData2,
          },
        ],
        grid: {
          left: '20%',
          bottom: '50%',
          right: '0%',
        },
        series: [
          {
            name: "违规次数",
            type: "pie",
            clockwise: true, //饼图的扇区是否是顺时针排布
            minAngle: 20, //最小的扇区角度（0 ~ 360）
            radius: ["45%", "65%"],
            center: ["70%", "35%"],
            avoidLabelOverlap: true,
            itemStyle: {
              normal: {
                borderColor: "#0a243e",
                borderWidth: 10,
                labelLine: {
                  show: true
                },
              },
            },
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                },
                show: false,
                textStyle: {
                  fontSize: 16,
                },
              },
            },
            data: ydata,
          },
        ],
        graphic: [
          {
            type: "group",
            left: "63%",
            top: "33%",
            children: [
              {
                type: "text",
                z: 100,
                style: {
                  fill: "#FFFFFF",
                  text: ["景区车辆"],
                  font: "bold 16px SourceHanSansCN-Medium",
                },
              },
            ],
          },
        ],
      };

      option && myChart.setOption(option);
    },
    array2obj(array, key) {
      var resObj = {};
      for (var i = 0; i < array.length; i++) {
        resObj[array[i][key]] = array[i];
      }
      return resObj;
    }
  },
};
</script>
<style lang="scss" scoped>
</style>